import type { AppProps } from 'next/app';
import ThemeProvider from '@/components/ThemeProvider';
import GlobalStyles from '@/styles/GlobalStyles';
import { ColorModeProvider } from '@/contexts/ColorModeContext';
import InlineScript from '@/styles/InlineScript';

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <ColorModeProvider>
      <InlineScript />
      <ThemeProvider>
        <GlobalStyles />
        {/* TODO: make your custom <Layout> */}
        <Component {...pageProps} />
      </ThemeProvider>
      {/* TODO: make your custom </Layout> */}
    </ColorModeProvider>
  );
};

export default App;
