import { ReactNode } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useColorModeContext } from '@/contexts/ColorModeContext';
import { darkTheme, lightTheme } from '@/styles/theme';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { colorMode } = useColorModeContext();
  return (
    <EmotionThemeProvider
      theme={colorMode === 'light' ? lightTheme : darkTheme}
    >
      {children}
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
