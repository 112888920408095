import { Theme } from '@emotion/react';
import { CommonColors } from '@/types/theme';

const colors: CommonColors = {
  blue: '#007bff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#ffffff',
  gray: '#7E8299',
  grayDark: '#3F4254',
  primary: '#3699FF',
  secondary: '#E4E6EF',
  success: '#1BC5BD',
  info: '#8950FC',
  warning: '#FFA800',
  danger: '#F64E60',
  light: '#F3F6F9',
  dark: '#181C32',
  lightgray: '#d1d3e0',
  lightLight: '#F3F6f9',
  dark90: '#282f48',
  dark75: '#3F4254',
  dark65: '#5e6278',
  dark50: '#7e8299',
  dark25: '#d1d3e0',
  mute: '#b5b5c2',
  lightDanger: '#fddbdf',
  lightPrimary: '#e1f0ff',
};

const lightTheme: Theme = {
  mode: 'light',
  colors: colors,
};

const darkTheme: Theme = {
  mode: 'dark',
  colors: colors,
};

const COLOR_MODE_KEY = 'color-mode';
const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';

const themeProperties = {
  'primary-color': {
    light: '#1E1E2D',
    dark: '#1E1E2D',
  },
  'mode-color': {
    light: lightTheme.mode,
    dark: darkTheme.mode,
  },
  'text-primary-color': {
    light: '#181C32',
    dark: 'white',
  },
  'text-secondary-color': {
    light: '#B7B7C4',
    dark: 'white',
  },
  'button-background-color': {
    light: '#F3F6F9',
    dark: 'black',
  },
  'background-color': {
    light: '#F7F7F7',
    dark: '#F7F7F7',
  },
  'border-color': {
    light: '#eaeaea',
    dark: '#222',
  },
  'body-color': {
    light: colors.white,
    dark: colors.dark,
  },
};

export {
  lightTheme,
  darkTheme,
  themeProperties,
  COLOR_MODE_KEY,
  INITIAL_COLOR_MODE_CSS_PROP,
};
